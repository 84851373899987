import React, { useEffect, useState } from "react"
import Seo from "components/seo"
import Layout from "layout"
import Monogram from "components/monogram"
//import WorkGrid from "components/work-grid"
import { Link } from "gatsby"

const IndexPage = ({ location }) => {
  const [introSeen, setIntroSeen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIntroSeen(true)
    }, 4000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Layout>
      <Seo title="Home" />

      <Monogram duration={3} isVisible={!introSeen} />
      <div>
        <div className="intro"></div>
        <div className="recent-work">
          <div className="container">
            <div className="text">
              <h2>
                Camp Quiet is a digital studio that builds websites for a
                variety of clients.
              </h2>
              <div className="col work-highlights">
                <h3>Highlighted Work</h3>
                <ul>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://autocamp.com/"
                    >
                      AutoCamp
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.grandarmy.com/"
                    >
                      GrandArmy
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://house.dwell.com/"
                    >
                      Dwell House
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://manualcreative.com/"
                    >
                      Manual
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://cncpt.ca/"
                    >
                      CNCPT
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.hxouse.com/"
                    >
                      HXOUSE
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://radio.sonos.com/"
                    >
                      Sonos Radio
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://varyer.com/"
                    >
                      Varyer
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://variant.fund/"
                    >
                      Variant
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://regrets-only.com/"
                    >
                      Regrets Only
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.symbolaudio.com/"
                    >
                      Symbol Audio
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://alright.studio/"
                    >
                      Alright Studio
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://ablspacesystems.com/"
                    >
                      ABL Space Systems
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.grandarmy.com/"
                    >
                      GrandArmy
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.evausallc.com/"
                    >
                      EVA USA LLC
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.playlab.org/"
                    >
                      PLAYLAB, INC
                    </a>
                    )
                  </li>

                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://asteras.com"
                    >
                      Asteras Kings
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://javaslehnstudio.com"
                    >
                      Javas Lehn
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://mozilla.design"
                    >
                      Mozilla Design
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.xxix.co/"
                    >
                      XXIX
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://50stateblueprint.aclu.org/"
                    >
                      ACLU: 50 State Blueprint
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.xxix.co/"
                    >
                      XXIX
                    </a>
                    )
                  </li>
                  <li>
                    <a
                      className="project"
                      rel="noreferrer"
                      target="_blank"
                      href="https://ibm.com/plex"
                    >
                      IBM Plex
                    </a>{" "}
                    (with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.xxix.co/"
                    >
                      XXIX
                    </a>
                    )
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col">
                  <p className="credit">Made in Virginia since 2011.</p>
                </div>
              </div>

              <div className="row">
                <div className="col addl-highlights">
                  <h3>Technology</h3>
                  <ul>
                    <li>NextJS</li>
                    <li>Sanity</li>
                    <li>Gatsby</li>
                    <li>Wordpress</li>
                    <li>Shopify</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Javascript</li>
                    <li>PHP</li>
                  </ul>
                </div>
                <div className="col addl-highlights">
                  <h3>Contact</h3>
                  <ul>
                    <li>
                      <Link to="/contact">Project Inquiry</Link>
                    </li>
                    <li>
                      <a href="https://twitter.com/campquiet">Twitter</a>
                    </li>
                    <li>
                      <a href="https://instagram.com/campquiet">Instagram</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
